import React, { useState, useEffect } from "react";
import '../../signup.css';

const CreateRole = ({ onSubmit, onlinkClick }) => {
  const [selectedRole, setSelectedRole] = useState("");
  const [acceptedDataCollection, setAcceptedDataCollection] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState("");
  const [acceptedHealthDisclaimer, setAcceptedHealthDisclaimer] = useState(false);

  useEffect(() => {
    // Array of image paths
    const images = [
      require("../../images/background1.jpg"),
      require("../../images/background2.jpg"),
      require("../../images/background3.jpg"),
      require("../../images/background4.jpg"),
      require("../../images/background5.jpg"),
    ];

    // Select a random image
    const randomImage = images[Math.floor(Math.random() * images.length)];
    setBackgroundImage(randomImage);
  }, []);

  const handleSubmit = () => {
    // Pass the selected role and privacy acceptance status to the parent component (App.js)
    onSubmit(selectedRole, acceptedDataCollection, acceptedHealthDisclaimer);
  };

  // Navigate to Privacy Policy page when clicked
  const handlePrivacyPolicyClick = (e) => {
    e.preventDefault(); // Prevent default anchor behavior
    onlinkClick('privacy-policy'); // Call the passed navigation function
  };
  const handleHealthDisclaimerClick = (e) => {
    e.preventDefault(); // Prevent default anchor behavior
    onlinkClick('privacy-policy'); // Call the passed navigation function
  };

  return (
    <div
      className="mainContainer-SelectRole-CR"
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <div className="mainContent-SelectRole">
        <h2>Select Your Role</h2>
        <select
          value={selectedRole}
          onChange={(e) => setSelectedRole(e.target.value)}
        >
          <option value="">Select a role</option>
          <option value="athlete">Athlete</option>
          <option value="patient">Patient</option>
          <option value="physio">Physio</option>
          <option value="pt">Personal Trainer</option>
          <option value="manager">Manager</option>
        </select>

        <div>
          <p>Privacy Policy Agreement:</p>
          <input
            type="checkbox"
            checked={acceptedDataCollection}
            onChange={(e) => setAcceptedDataCollection(e.target.checked)}
          />
          <label>
            I accept the collection of data for use in this app.
            <br />
            <a href="/" onClick={handlePrivacyPolicyClick} rel="noopener noreferrer">
              View Privacy Policy
            </a>
          </label>
        </div>

        {/* Conditionally render health disclaimer if role is athlete or patient */}
        {(selectedRole === "athlete" || selectedRole === "patient") && (
          <div>
            <p>Health Disclaimer:</p>
            <input
              type="checkbox"
              checked={acceptedHealthDisclaimer}
              onChange={(e) => setAcceptedHealthDisclaimer(e.target.checked)}
            />
            <label>
              I accept the health disclaimer whilst using this app for physical activities.
              <br />
            <a href="/" onClick={handleHealthDisclaimerClick} rel="noopener noreferrer">
              View Health Disclaimer
            </a>
            </label>
          </div>
        )}

        <button onClick={handleSubmit} disabled={!selectedRole || (selectedRole === "athlete" || selectedRole === "patient") && !acceptedHealthDisclaimer}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default CreateRole;
