import React, { useRef, useState } from 'react';
import './physioContent.css';
import VideoSelector from './videoSelector';
import { FaVideo, FaStop } from 'react-icons/fa';

const PhysioVideos = ({ patient }) => {
  const currentPatient = patient[0].UserID;
  const [videos, setVideos] = useState([
    { id: 1, title: 'Squat Tutorial', src: '' },
    { id: 2, title: 'Deadlift Tutorial', src: '' },
  ]);

  const [recording, setRecording] = useState(false);
  const [videoURL, setVideoURL] = useState('');
  const [showVideoNameInput, setShowVideoNameInput] = useState(false);
  const [videoName, setVideoName] = useState('');
  const [reloadVideos, setReloadVideos] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0); // State for tracking upload progress
  const videoRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const chunks = useRef([]);
  const fileInputRef = useRef(null);

  const handleArchive = (id) => {
    setVideos(videos.filter((video) => video.id !== id));
  };

  const handleCreate = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      videoRef.current.srcObject = stream;
      mediaRecorderRef.current = new MediaRecorder(stream);

      chunks.current = [];

      mediaRecorderRef.current.ondataavailable = (event) => {
        chunks.current.push(event.data);
      };

      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(chunks.current, { type: 'video/mp4' });
        const url = URL.createObjectURL(blob);
        setVideoURL(url);
        setShowVideoNameInput(true);  // Show the input field when recording stops
      };

      mediaRecorderRef.current.start();
      setRecording(true);
    } catch (error) {
      console.error('Error accessing media devices:', error);
    }
  };

  const stopRecording = () => {
    mediaRecorderRef.current.stop();
    videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
    setRecording(false);
  };

  const handleVideoNameSubmit = async (isUpload = false) => {
    const videoId = Date.now();
    const blob = isUpload ? uploadedFile : new Blob(chunks.current, { type: 'video/mp4' });
    const finalVideoName = `${videoName} - ${currentPatient.name}`;
    const url = URL.createObjectURL(blob);
    setVideos([...videos, { id: videoId, title: finalVideoName, src: url }]);

    // Generate the presigned URL on the backend
    try {
      const fileName = `${currentPatient}+${videoName}.mp4`;
      const presignedResponse = await fetch('https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/videos/upload', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ file_name: fileName }),
      });

      if (!presignedResponse.ok) {
        throw new Error('Failed to get presigned URL');
      }

      const presignedUrl = await presignedResponse.text();

      // Upload the video to S3 using the presigned URL with progress tracking
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', presignedUrl, true);
      xhr.setRequestHeader('Content-Type', 'application/octet-stream');

      // Track the progress of the upload
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentComplete = Math.round((event.loaded / event.total) * 100);
          setUploadProgress(percentComplete);
        }
      };

      xhr.onload = () => {
        if (xhr.status === 200) {
          console.log('Video uploaded successfully');
          setReloadVideos(!reloadVideos);
          setUploadProgress(0); // Reset progress after upload is done
        } else {
          console.error('Failed to upload video:', xhr.statusText);
        }
      };

      xhr.onerror = () => {
        console.error('Error during upload:', xhr.statusText);
      };

      xhr.send(blob);

    } catch (error) {
      console.error('Error during upload:', error);
    }

    setShowVideoNameInput(false);
    setVideoName('');  // Clear the input field
  };

  const handleVideoSelect = (video) => {
    setVideos([...videos, { id: video.id, title: video.title, src: video.url }]);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploadedFile(file);
      const fileURL = URL.createObjectURL(file);
      setVideoURL(fileURL);
      setShowVideoNameInput(true);
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();  // Simulate click on the hidden file input
  };

  return (
    <div className="container">
      <h2>Physio Videos</h2>
      <div className="video-scroll-container">
        <VideoSelector userId={currentPatient} reload={reloadVideos} onSelectVideo={handleVideoSelect} />
      </div>
      {/* <div className="video-capture-container">
        <video ref={videoRef} autoPlay playsInline width="300" height="200" style={{ display: recording ? 'block' : 'none' }}></video>
        {recording ? (
          <button onClick={stopRecording}><FaStop /> Stop Recording</button>
        ) : (
          <button onClick={handleCreate}><FaVideo /> Create New Video</button>
        )}
      </div> */}
      {videoURL && (
        <div>
          <h3>Recorded/Uploaded Video:</h3>
          <video src={videoURL} controls width="300" height="200"></video>
        </div>
      )}
      {showVideoNameInput && (
        <div className="video-name-input">
          <h3>Enter Video Name</h3>
          <input
            type="text"
            value={videoName}
            onChange={(e) => setVideoName(e.target.value)}
            placeholder="Enter video name"
          />
          <button onClick={() => handleVideoNameSubmit(uploadedFile !== null)}>Submit</button>
        </div>
      )}
      <div>
        {/* <h3>New or upload video</h3> */}
        <button onClick={triggerFileInput}>Take or upload video</button>
        <input
          type="file"
          accept="video/*"
          ref={fileInputRef}
          style={{ display: 'none' }}  // Hide the file input
          onChange={handleFileChange}
        />
      </div>
      {uploadProgress > 0 && (
        <div>
          <h4>Upload Progress: {uploadProgress}%</h4>
          <progress value={uploadProgress} max="100"></progress>
        </div>
      )}
    </div>
  );
};

export default PhysioVideos;
