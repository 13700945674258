import React, { useState, useEffect } from 'react';
import Profile from '../../../login/profile';
import './Profilecontent.css'; // Import the CSS file
import CryptoJS from 'crypto-js';
import AddPhysio from '../physio/addPhysio';

const Profilecontent = ({ userID }) => {
  const [uuid, setUuid] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [athleteProfile, setAthleteProfile] = useState(null); 
  const [newHeight, setNewHeight] = useState(''); 
  const [newWeight, setNewWeight] = useState(''); 
  const [newPrimarySport, setNewPrimarySport] = useState(''); 
  const [isUpdateProfileVisible, setIsUpdateProfileVisible] = useState(false); // State for toggling update profile section

  const user = ""; // Replace this with actual user data

  const decryptField = (encryptedValue, encryptionKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedValue, encryptionKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  }

  // Fetch Athlete Profile
  const fetchAthleteProfile = async () => {
    console.log('user ID:', userID);
    console.log(`https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/athleteProfile/getProfile?dataID=${userID.dataID}`);
    try {
      const response = await fetch(`https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/athleteProfile/getProfile?dataID=${userID.dataID}`);
      if (response.ok) {
        const data = await response.json();
        const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;
        console.log('d height:' , decryptField(data.Height, encryptionKey))
        setAthleteProfile({
          height: `${decryptField(data.Height, encryptionKey)} cm`,
          weight: `${decryptField(data.Weight, encryptionKey)} kg`,
          primarySport: decryptField(data["Primary Sport"], encryptionKey),
          vo2Max: `${decryptField(data.TestResults.Vo2Max, encryptionKey)} ml/kg/min`,
          otherTestResults: `Lactate Threshold: ${decryptField(data.TestResults.LactateThreshold, encryptionKey)} mmol/L`
        });
      } else {
        console.error('Failed to fetch athlete profile');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    if (userID) {
      fetchAthleteProfile();
    }
  }, [userID]);

  // Handle Join Request
  const handleJoinRequest = async () => {
    try {
      const response = await fetch('/api/join-pt', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ptUuid: uuid, athleteId: user.sub }), 
      });

      if (response.ok) {
        setStatusMessage('Request sent successfully!');
      } else {
        setStatusMessage('Failed to send request.');
      }
    } catch (error) {
      console.error('Error:', error);
      setStatusMessage('An error occurred.');
    }
  };

  const encryptField = (value, encryptionKey) => {
    return CryptoJS.AES.encrypt(value, encryptionKey).toString();
  }

  // Handle Profile Update
  const handleUpdateProfile = async () => {
    try {
      const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;
      const encryptedBody = JSON.stringify({
        dataID: {
          S: encryptField(userID.dataID, encryptionKey),
        },
        Height: {
          S: encryptField(newHeight || athleteProfile.height.split(' ')[0], encryptionKey),
        },
        Weight: {
          S: encryptField(newWeight || athleteProfile.weight.split(' ')[0], encryptionKey),
        },
        "Primary Sport": {
          S: encryptField(newPrimarySport || athleteProfile.primarySport, encryptionKey),
        },
        TestResults: {
          M: {
            LactateThreshold: {
              S: encryptField(athleteProfile.otherTestResults.split(' ')[2], encryptionKey),
            },
            Vo2Max: {
              S: encryptField(athleteProfile.vo2Max.split(' ')[0], encryptionKey),
            },
          },
        },
      });
      const response = await fetch('https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/athleteProfile/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: encryptedBody
      });

      if (response.ok) {
        setStatusMessage('Profile updated successfully!');
        fetchAthleteProfile(); // Refetch the updated profile
      } else {
        setStatusMessage('Failed to update profile.');
      }
    } catch (error) {
      console.error('Error:', error);
      setStatusMessage('An error occurred.');
    }
  };

  return (
    <div className="profile-content-container">
      <div className="profile-header">
        <h3>Athlete Profile</h3>
      </div>
      
      {/* Athlete Profile Section */}
      <div className="athlete-profile-container">
        <h3>Athlete Profile</h3>
        
        {/* User Data Display */}
        {athleteProfile ? (
          <div className="user-display-section">
            <h4>Personal Information</h4>
            <p><strong>Height:</strong> {athleteProfile.height}</p>
            <p><strong>Weight:</strong> {athleteProfile.weight}</p>
            <p><strong>Primary Sport:</strong> {athleteProfile.primarySport}</p>
          </div>
        ) : (
          <p>Loading athlete profile...</p>
        )}

        {/* Practitioner Data Display */}
        {athleteProfile && (
          <div className="practitioner-display-section">
            <h4>Practitioner Results</h4>
            <p><strong>VO2 Max:</strong> {athleteProfile.vo2Max}</p>
            <p><strong>Other Test Results:</strong> {athleteProfile.otherTestResults}</p>
          </div>
        )}
      </div>

      {/* Toggle Button for Update Profile Section */}
      <button onClick={() => setIsUpdateProfileVisible(!isUpdateProfileVisible)}>
        {isUpdateProfileVisible ? 'Hide Update Profile' : 'Update Profile'}
      </button>

      {/* Conditionally Render Update Profile Section */}
      {isUpdateProfileVisible && (
        <div className="update-profile-container">
          <h3>Update Profile</h3>
          <input
            type="text"
            value={newHeight}
            onChange={(e) => setNewHeight(e.target.value)}
            placeholder="Enter new height (cm)"
          />
          <input
            type="text"
            value={newWeight}
            onChange={(e) => setNewWeight(e.target.value)}
            placeholder="Enter new weight (kg)"
          />
          <input
            type="text"
            value={newPrimarySport}
            onChange={(e) => setNewPrimarySport(e.target.value)}
            placeholder="Enter new primary sport"
          />
          <button onClick={handleUpdateProfile}>Update Profile</button>
          {statusMessage && (
            <p className={`status-message ${statusMessage.includes('error') ? 'error' : ''}`}>
              {statusMessage}
            </p>
          )}
        </div>
      )}
      <AddPhysio userID={userID}/>

      {/* Auth Profile Info Section */}
      <div className="auth-profile-container">
        <h3>Auth Profile Info</h3>
        <Profile /> {/* Assuming Profile component displays the auth profile info */}
      </div>
    </div>
  );
};

export default Profilecontent;
