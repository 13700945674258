import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Header from "../mainContent/manager/header";
import Profilecontent from "../mainContent/physio/profile/profilepage";
import PhysioMainContent from "../mainContent/physio/home/physioMainContent";
import Training from "../mainContent/physio/training/trainingContent";
import About from "../mainContent/about/about";
import PracticeManagement from "../mainContent/manager/practice/practicemanager";
import PractitionerView from "../mainContent/manager/practice/practitionerViewer"; // Import PractitionerView
import LicenceManager from "../mainContent/manager/licence/licenceManager";
import Footer from '../mainContent/footer'
import PrivacyPolicyContent from '../mainContent/privacyPolicy'
import "./layouts.css";

const ManagerLayout = (dataID) => {
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [patientData, setPatientData] = useState(null);
  const navigate = useNavigate();

  const handleLinkClick = (page) => {
    navigate(page); // Navigate to the appropriate route
  };

  const handlePatientSelect = async (patient) => {
    setSelectedPatient(patient);
    if (patient && patient.ptID) {
      try {
        const response = await fetch(
          `https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/physio/PTData/get?userID=${patient.ptID}`
        );
        const data = await response.json();
        setPatientData(data);
      } catch (error) {
        console.error("Error fetching patient data:", error);
      }
    }
  };

  const refreshPTData = async () => {
    if (selectedPatient && selectedPatient.ptID) {
      try {
        const response = await fetch(
          `https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/physio/PTData/get?userID=${selectedPatient.ptID}`
        );
        const data = await response.json();
        setPatientData(data);
      } catch (error) {
        console.error("Error fetching patient data:", error);
      }
    }
  };

  return (
    <div>
      <Header onLinkClick={handleLinkClick} />
      <div className="content" style={{ backgroundImage: `url(/backgroundBlob.svg)` }}>
        <Routes>
          <Route
            path="home"
            element={
              <PhysioMainContent
                handlePatientSelect={handlePatientSelect}
                selectedPatient={selectedPatient}
                patientData={patientData}
                refreshPTData={refreshPTData}
                dataID={dataID}
              />
            }
          />
          <Route
            path="training"
            element={
              <Training
                handlePatientSelect={handlePatientSelect}
                selectedPatient={selectedPatient}
                patientData={patientData}
                refreshPTData={refreshPTData}
                dataID={dataID}
              />
            }
          />
          <Route path="about" element={<About />} />
          <Route path="profile" element={<Profilecontent dataID={dataID} />} />
          
          {/* Practice Management with Nested Practitioner View */}
          <Route path="practice" element={<PracticeManagement dataID={dataID}/>} />
          <Route path="practice/practitioner/:id" element={<PractitionerView />} />
          <Route path="licences" element={<LicenceManager dataID={dataID}/>}/>
          <Route path="privacy-policy" element={<PrivacyPolicyContent/>} />
        </Routes>
      </div>
      <Footer onLinkClick={handleLinkClick}/>
    </div>
  );
};

export default ManagerLayout;
