import React, {useState} from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Header from '../mainContent/patient/header'; 
import PhysioContent from '../mainContent/athlete/physio/physioContent';
import AdviceContent from '../mainContent/athlete/advice/advice';
import About from '../mainContent/about/about';
import Profilecontent from '../mainContent/athlete/profile/profilepage';
import Booking from '../mainContent/booking/bookApp/bookingPage';
import Payment from '../mainContent/booking/payment/payementPage';
import Footer from '../mainContent/footer'
import PrivacyPolicyContent from '../mainContent/privacyPolicy'
import './layouts.css'

const PatientLayout = (dataID) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const userID = dataID.dataID;
  const navigate = useNavigate();  // React Router's hook for navigation

  // Function to handle navigation when a link in the header is clicked
  const handleLinkClick = (page) => {
    navigate(page);  // Navigate to the appropriate route
  };

  return (
    <div>
      <Header onLinkClick={handleLinkClick} isMobileMenuOpen={isMobileMenuOpen} setIsMobileMenuOpen={setIsMobileMenuOpen} />  {/* Include the Header with the navigation handler */}
      <div className="content" style={{ marginTop: isMobileMenuOpen ? '250px' : '0' , backgroundImage: `url(/backgroundCir.svg)`}} >
        <Routes>
          <Route path="physio" element={<PhysioContent userID={dataID}/>} />
          <Route path="advice" element={<AdviceContent />} />
          <Route path="about" element={<About />} />
          <Route path="profile" element={<Profilecontent userID={dataID} />} />
          <Route path="booking" element={<Booking/>}/>
          <Route path="payment" element={<Payment/>}/>
          <Route path="privacy-policy" element={<PrivacyPolicyContent/>} />
          {/* Add more routes as necessary */}
        </Routes>
      </div>
      <Footer onLinkClick={handleLinkClick}/>
    </div>
  );
};

export default PatientLayout;
