import React from "react";
import PatientDropdown from "../home/PatientDropdown";
import PatientTrainingStatus from "./PatientTrainingStatus";
import TrainingSchedule from "./TrainingSchedule";

const Training = ({ handlePatientSelect, selectedPatient, patientData, refreshPTData, dataID }) => {
  return (
    <div>
      <button onClick={refreshPTData}>Refresh Data</button>
      <PatientDropdown onSelect={handlePatientSelect} dataID={dataID} />
      {patientData && (
        <>
          <h2 className="patientName">{selectedPatient.ptName}</h2>
          <PatientTrainingStatus patient={patientData} />
          <TrainingSchedule patient={patientData} />
        </>
      )}
    </div>
  );
};

export default Training;
