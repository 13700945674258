import React from "react";
import TodaysTrainingPlan from './TodaysTrainingPlan';
import WodSelector from './WodSelector';

const AthleteMainContent = (dataID) => {

    console.log(dataID)
    return(
        <div>
        <TodaysTrainingPlan userID={dataID.dataID}/>
        <WodSelector/>
        </div>
    )
}

export default AthleteMainContent