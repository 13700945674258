import React, { useState } from 'react';
import './athleteheader.css';
import LogoutButton from '../../login/logout';
import Logo from '../../images/logoLight.png';

const Header = ({ onLinkClick, isMobileMenuOpen, setIsMobileMenuOpen }) => {
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen); // Toggles the state
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false); // Close the menu when a link is clicked
  };

  return (
    <header className='athlete-header'>
      <img src={Logo} alt="Logo" className="athlete-logo"></img>
      <h1 className="athlete-header-title">Patient</h1>
      
      {/* Hamburger Icon for mobile view */}
      <div className="athlete-hamburger" onClick={toggleMobileMenu}>
        <div className="athlete-bar"></div>
        <div className="athlete-bar"></div>
        <div className="athlete-bar"></div>
      </div>

      {/* Standard Nav for larger screens */}
      <nav className="athlete-desktop-nav">
        <ul>
          <li><a onClick={() => onLinkClick('physio')}>Physio</a></li>
          <li><a onClick={() => onLinkClick('advice')}>Advice</a></li>
          <li><a onClick={() => onLinkClick('about')}>About</a></li>
          <li><a onClick={() => onLinkClick('profile')}>Profile</a></li>
          <li><LogoutButton/></li>
        </ul>
      </nav>

      {/* Mobile flyout menu - toggle with state */}
      <div className={isMobileMenuOpen ? 'athlete-mobile-menu athlete-mobile-menu-open' : 'athlete-mobile-menu'}>
        <ul>
          <li><a onClick={() => { onLinkClick('physio'); closeMobileMenu(); }}>Physio</a></li>
          <li><a onClick={() => { onLinkClick('advice'); closeMobileMenu(); }}>Advice</a></li>
          <li><a onClick={() => { onLinkClick('about'); closeMobileMenu(); }}>About</a></li>
          <li><a onClick={() => { onLinkClick('profile'); closeMobileMenu(); }}>Profile</a></li>
          <li><LogoutButton /></li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
