import React, { useState } from 'react';
import CryptoJS from 'crypto-js';

const AddPhysio = ({ userID }) => {
    const [physioNumber, setPhysioNumber] = useState('physio-');
    const [physioData, setPhysioData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [physioName, setPhysioName] = useState("");
    const userid = userID.dataID;
  
    const handleInputChange = (e) => {
      const inputValue = e.target.value;
      // Ensure the input value always starts with 'physio-'
      if (inputValue.startsWith('physio-')) {
        setPhysioNumber(inputValue);
      }
    };
  
    const decryptField = (encryptedValue, encryptionKey) => {
      const bytes = CryptoJS.AES.decrypt(encryptedValue, encryptionKey);
      return bytes.toString(CryptoJS.enc.Utf8);
    }

    const fetchPhysioData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch(`https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/joinPhysio/athleteRequest?publicPhysioID=${physioNumber}`);
        if (!response.ok) {
          throw new Error('Failed to fetch physio data');
        }
        const data = await response.json();
        console.log(data)
        setPhysioData(data);
        const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;
        const enName = data.Name;
        const name = decryptField(enName, encryptionKey)
        setPhysioName(name);

      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };
  
    const handleAddToGroup = async () => {
      if (!physioData) return;
      setIsLoading(true);
      setError(null);

      try {
        const response = await fetch('https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/joinPhysio/requestToJoin', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            physioID: physioData.physioID,
            UserID: userID.dataID
          })
        });

        if (!response.ok) {
          throw new Error('Failed to add physio to group');
        }

        // If the request is successful, show an alert
        alert(`Successfully added Physio ${physioData.Name} (ID: ${physioData.physioID}) to the group!`);

        // Clear the input and state
        setPhysioData(null);
        setPhysioNumber('physio-');
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };
  
    return (
      <div>
        <h1>Add Physio to Group</h1>
        <input 
          type="text" 
          value={physioNumber} 
          onChange={handleInputChange} 
          placeholder="Enter Physio Number" 
          
        />
        <button onClick={fetchPhysioData} disabled={!physioNumber || isLoading || physioNumber === 'physio-'}>
          {isLoading ? 'Loading...' : 'Add to Physio Group'}
        </button>
  
        {error && <p style={{color: 'red'}}>{error}</p>}
  
        {physioData && (
          <div>
            <p>Physio Name: {physioName}</p>
            {/* <p>Physio ID: {physioData.physioID}</p> */}
            <button onClick={handleAddToGroup} disabled={isLoading}>Confirm Add to Group</button>
          </div>
        )}
      </div>
    );
};

export default AddPhysio;
