import React from "react";
import { Routes, Route, Navigate } from 'react-router-dom';
import CreateAthleteProfile from '../mainContent/createProfile/createAthleteProfile';
import CreatephysioProfile from '../mainContent/createProfile/createPhysioProfile';
import CreateManagerProfile from "../mainContent/createProfile/createManagerProfile";

const CreateProfileLayout = ({ userRole, userDataID, onProfileCreated }) => {
  console.log(userRole)
  return (
    <div>
      <Routes>
        { userRole === 'athlete' && (
          <Route
            path="athlete"
            element={
              <CreateAthleteProfile
              dataID={userDataID}
                onProfileCreated={onProfileCreated}
              />
            }
          />
        )}
        { userRole === 'patient' && (
          <Route
            path="patient"
            element={
              <CreateAthleteProfile
              dataID={userDataID}
                onProfileCreated={onProfileCreated}
              />
            }
          />
        )}
        {userRole === 'physio' && (
          <Route
            path="physio"
            element={
              <CreatephysioProfile
              dataID={userDataID}
                onProfileCreated={onProfileCreated} 
              />
            }
          />
        )}
        {userRole === 'manager' && (
          <Route
            path="manager"
            element={
              <CreateManagerProfile
              dataID={userDataID}
                onProfileCreated={onProfileCreated} 
              />
            }
          />
        )}        

        {/* Conditionally navigate based on userRole */}
        <Route
          path="*"
          element={
            userRole === 'athlete'
              ? <Navigate to="/create-profile/athlete" />
              : userRole === 'physio'
              ? <Navigate to="/create-profile/physio" />
              : userRole === 'manager'
              ? <Navigate to="/create-profile/manager"/>
              : userRole === 'patient'
              ? <Navigate to="/create-profile/patient"/>
              : null // Or redirect to some error page or fallback path if role is missing
          }
        />
      </Routes>
    </div>
  );
};

export default CreateProfileLayout;
