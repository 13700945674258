import React, { useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react' // Import the Auth0 hook
import AthleteLayout from './layouts/athlete' // Import Athlete Layout
import PhysioPage from './layouts/physio' // Assume you have a physio layout
import ManagerLayout from './layouts/manager'
import PatientLayout from './layouts/patient'
import LoginLayout from './layouts/login' // Import the Login layout
import CreateRole from './layouts/createrole'
import CreateProfileLayout from './layouts/createprofile'

function App() {
  const { isAuthenticated, user } = useAuth0(); // Get authentication status from Auth0
  const [userRole, setUserRole] = useState(null); // State to manage user role
  const [userDataID, setUserDataID] = useState('');
  const [isLoading, setIsLoading] = useState(true); // Loading state to handle API data fetching
  const [showRoleSelection, setShowRoleSelection] = useState(false); // Role selection state
  const [profileCreated, setProfileCreated] = useState(false); // State to check if profile is created

  // Fetch user data when the component mounts or when authentication status changes
  useEffect(() => {
    if (isAuthenticated && user) {
      fetchData();
    } else {
      setIsLoading(false); // If not authenticated, stop loading
    }
  }, [isAuthenticated, user]);

  const fetchData = async () => {
    try {
      const response = await fetch(
        `https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/users/getDLSUUID?email=${user.email}`
      );

      if (response.status === 200) {
        const data = await response.json();
        setUserRole(data.role);
        setUserDataID(data.dataID);

        if (data.role === 'athlete') {
          const response1 = await fetch(
            `https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/athleteProfile/getProfile?dataID=${data.dataID}`
          );
          setProfileCreated(response1.status === 200);
        } else {
          const response3 = await fetch(
            `https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/joinPhysio/getUsers?dataID=${data.dataID}`
          );
          setProfileCreated(response3.status === 200);
        }
      } else {
        // User doesn't exist, prompt for role selection
        setShowRoleSelection(true);
        setProfileCreated(false);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    } finally {
      setIsLoading(false); // Set loading to false after fetching data
    }
  };

  // Handle the role selection and privacy policy acceptance from CreateRole
  const handleRoleSubmit = async (selectedRole, acceptedDataCollection) => {
    if (!acceptedDataCollection) {
      alert('You must accept the data collection policy to proceed.');
      return;
    }

    try {
      const createUserResponse = await fetch(
        `https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/users/create-user`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email: user.email, role: selectedRole }),
        }
      );

      if (createUserResponse.ok) {
        setShowRoleSelection(false);
        // Re-fetch the user data after submitting the role
        await fetchData();
      } else {
        console.error('Error creating user:', createUserResponse.statusText);
      }
    } catch (error) {
      console.error('Error creating user:', error);
    }
  };

  const handleProfileCreated = () => {
    // Re-fetch the user data to check the updated profile status
    fetchData();
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{backgroundColor: '#000'}}>
      <Router>
        <Routes>
          {!isAuthenticated ? (
            <>
              <Route path="/login/*" element={<LoginLayout />} />
              <Route path="*" element={<Navigate to="/login/home" />} />
            </>
          ) : showRoleSelection ? (
            <>
              <Route
                path="/create-role/*"
                element={<CreateRole onSubmit={handleRoleSubmit} />}
              />
              <Route path="*" element={<Navigate to="/create-role" />} />
            </>
          ) : !profileCreated ? (
            <>
              <Route path="/create-profile/*" element={<CreateProfileLayout userRole={userRole} userDataID={userDataID} onProfileCreated={handleProfileCreated} />} />
              <Route path="*" element={<Navigate to="/create-profile" />} />
            </>
          ) : (
            <>
              {userRole === "athlete" && (
                <>
                  <Route
                    path="/athlete/*"
                    element={<AthleteLayout dataID={userDataID} />}
                  />
                  <Route path="*" element={<Navigate to="/athlete/home" />} />
                </>
              )}
              {userRole === "patient" && (
                <>
                  <Route
                    path="/patient/*"
                    element={<PatientLayout dataID={userDataID} />}
                  />
                  <Route path="*" element={<Navigate to="/patient/physio" />} />
                </>
              )}
              {userRole === "physio" && (
                <>
                  <Route
                    path="/physio/*"
                    element={<PhysioPage dataID={userDataID} />}
                  />
                  <Route path="*" element={<Navigate to="/physio/home" />} />
                </>
              )}
              {userRole === "manager" && (
                <>
                  <Route
                    path="/manager/*"
                    element={<ManagerLayout dataID={userDataID} />}
                  />
                  <Route path="*" element={<Navigate to="/manager/home" />} />
                </>
              )}
            </>
          )}

          <Route path="*" element={<Navigate to="/login/home" />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
