import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);

const MyCalendar = ({ bookings, dailySchedule, types, times, onSlotSelected, fetchBookingsForMonth }) => {
  const [view, setView] = useState('month');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedType, setSelectedType] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [monthSlots, setMonthSlots] = useState([]);

  const events = bookings.map((booking) => ({
    title: 'Booking',
    start: new Date(booking.startTime),
    end: new Date(booking.endTime),
    url: booking.telehealthUrl,
    type: 'booking',
  }));

  useEffect(() => {
    if (selectedDate && selectedTime) {
      const slots = getAvailableSlots(selectedDate);
      setAvailableSlots(slots);
    }
  }, [selectedTime, selectedDate]);

  const calculateFreeSlots = (dayOfWeek, selectedDate) => {
    if (!selectedTime) return [];

    const schedule = dailySchedule.find((schedule) => schedule.dayOfWeek === dayOfWeek);
    if (!schedule) return [];

    const { startTime, endTime } = schedule;
    const startHour = moment(`${moment(selectedDate).format('YYYY-MM-DD')}T${startTime}:00`);
    const endHour = moment(`${moment(selectedDate).format('YYYY-MM-DD')}T${endTime}:00`);

    const slots = [];
    let currentSlot = startHour.clone();
    const MAX_SLOTS = 48;
    let slotCount = 0;

    while (currentSlot.isBefore(endHour) && slotCount < MAX_SLOTS) {
      const nextSlot = currentSlot.clone().add(selectedTime, 'minutes');
      if (nextSlot.isAfter(endHour)) break;
      slots.push({ start: currentSlot.toDate(), end: nextSlot.toDate(), type: 'free' });
      currentSlot = nextSlot;
      slotCount++;
    }

    return slots;
  };

  const getAvailableSlots = (selectedDate) => {
    if (!selectedTime) return [];

    const dayOfWeek = moment(selectedDate).day();
    const freeSlots = calculateFreeSlots(dayOfWeek, selectedDate);

    const bookedSlots = events.filter((event) =>
      moment(event.start).isSame(selectedDate, 'day')
    );

    const availableSlots = freeSlots.filter((slot) => {
      return !bookedSlots.some(
        (booked) =>
          moment(slot.start).isBetween(booked.start, booked.end, null, '[)') ||
          moment(slot.end).isBetween(booked.start, booked.end, null, '(]')
      );
    });

    return availableSlots;
  };

  const calculateMonthlySlots = (date) => {
    const daysInMonth = moment(date).daysInMonth();
    const newMonthSlots = [];

    for (let day = 1; day <= daysInMonth; day++) {
      const currentDate = moment(date).date(day);
      const freeSlots = getAvailableSlots(currentDate);
      newMonthSlots.push({
        title: `${freeSlots.length} free slots`,
        start: currentDate.toDate(),
        end: currentDate.toDate(),
        type: 'free-slots',
        freeSlotCount: freeSlots.length,
      });
    }

    setMonthSlots(newMonthSlots);
  };

  useEffect(() => {
    if (selectedType && selectedTime) {
      calculateMonthlySlots(selectedDate);
    }
  }, [selectedType, selectedTime]);

  const handleDaySelection = (slotInfo) => {
    setSelectedDate(slotInfo.start);
    const slots = getAvailableSlots(slotInfo.start);
    if (slots.length > 0) {
      setAvailableSlots(slots);
      setView('day'); // Switch to day view after selecting a day with free slots
    } else {
      alert('No available slots for this day.');
    }
  };

  const handleSlotSelection = (slot) => {
    setSelectedSlot(slot);
  };

  const handleBookAppointment = () => {
    if (onSlotSelected && selectedSlot) {
      onSlotSelected({
        selectedType,
        selectedTime,
        slot: {
          start: selectedSlot.start,
          end: selectedSlot.end,
        },
      });
    }
  };

  const eventStyleGetter = (event) => {
    let style = {
      backgroundColor: 'lightgray',
      borderRadius: '5px',
      opacity: 0.8,
      color: 'black',
      border: '0px',
      display: 'block',
    };

    if (event.type === 'free') {
      style.backgroundColor = 'lightgreen';
    }

    if (event.type === 'free-slots') {
      style.backgroundColor = 'lightblue';
    }

    return { style };
  };

  const handleTypeChange = (e) => {
    const selectedIndex = e.target.value;
    if (selectedIndex === '') {
      setSelectedType(null);
      setSelectedTime(null);
      setSelectedSlot(null);
    } else {
      setSelectedType(types[selectedIndex]);
      setSelectedTime(times[selectedIndex]);
      setSelectedSlot(null);
    }
  };

  const handleNavigate = (date) => {
    setSelectedDate(date);
    fetchBookingsForMonth(moment(date).month() + 1); // Assuming this function fetches bookings for the month
  };

  return (
    <div>
      <h3>Calendar - Select Available Time Slot</h3>

      <div>
        <label>Select Appointment Type and Duration:</label>
        <select
          value={selectedType === null ? '' : types.indexOf(selectedType)}
          onChange={handleTypeChange}
        >
          <option value=''>Select an appointment type</option>
          {types.map((type, index) => (
            <option key={type} value={index}>
              {type} ({times[index]} minutes)
            </option>
          ))}
        </select>
      </div>

      {selectedType && selectedTime ? (
        <div>
          <Calendar
            localizer={localizer}
            events={view === 'day' ? availableSlots : [...events, ...monthSlots]} // Display only free slots in day view
            startAccessor='start'
            endAccessor='end'
            style={{ height: 500 }}
            defaultView='month'
            views={['month', 'day']}
            view={view}
            date={selectedDate || new Date()}
            selectable
            onSelectSlot={handleDaySelection}
            onView={setView}
            onNavigate={handleNavigate} // Handle navigation to other months
            eventPropGetter={eventStyleGetter}
          />

          {view === 'day' && (
            <div>
              <h4>Select a Time Slot</h4>
              {availableSlots.length > 0 ? (
                <ul>
                  {availableSlots.map((slot, index) => (
                    <li key={index} onClick={() => handleSlotSelection(slot)}>
                      {moment(slot.start).format('HH:mm')} - {moment(slot.end).format('HH:mm')}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No available slots for the selected day.</p>
              )}
            </div>
          )}

          <button
            onClick={handleBookAppointment}
            disabled={!selectedSlot}
            style={{
              marginTop: '10px',
              padding: '10px',
              backgroundColor: selectedSlot ? 'blue' : 'gray',
              color: 'white',
              border: 'none',
              cursor: selectedSlot ? 'pointer' : 'not-allowed',
            }}
          >
            Book Appointment
          </button>
        </div>
      ) : (
        <p>Please select an appointment type to see available slots.</p>
      )}
    </div>
  );
};

export default MyCalendar;
