import { React, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";

import MyCalendar from "./calendar";

const Booking = () => {
  const location = useLocation();
  const [localPractitioner, setLocalPractitioner] = useState("");
  const [localPatient, setLocalPatient] = useState("");
  const [physioID, setPhysioID] = useState("");
  const [practitioners, setPractitioners] = useState([]);
  const [patients, setPatients] = useState([]);
  const [appointmentTypes, setAppointmentTypes] = useState([]);
  const [appointmentTypeIDs, setAppointmentTypeIDs] = useState([]);
  const [appointmentTypeTimes, setAppointmentTypeTimes] = useState([]);
  const [businesses, setBusinesses] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [dailySchedule, setDailySchedule] = useState([]);
  const [athBusID, setAthBusID] = useState("");
  const [clApiKey, setCLAPIKey] = useState("");
  const [businessData, setBusinessData] = useState("");
  const [selectedBusiness, setSelectedBusiness] = useState("");
  const [practitionerID, setPRactitionerID] = useState("");
  const [patientID, setPatientID] = useState("");
  const [businessID, setBusinessID] = useState("");
  const [appointmentTypeID, setAppointmentTypeID] = useState("");
  const [appStartTime, setAppStartTime] = useState("");
  const [appEndTime, setAppEndTime] = useState("");
  const [userID, setUserID] = useState("");
  const [physioList, setPhysioList] = useState([]);
  const [selectedPhysio, setSelectedPhysio] = useState("");
  const [physioListIDs, setPhysioListIDs] = useState([]);
  const [practAppTypes, setPracAppTypes] = useState([]);

  const navigate = useNavigate();

  const fetchPhysioData = async (userid) => {
    try {
      const response = await fetch(
        `https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/athlete/getPhysioPTData?userID=${userid}`
      );
      const result = await response.json();
      console.log('result:', result[0])
      const belongsToList = result[0].belongsTo;
      setPhysioListIDs(belongsToList);
      if (belongsToList.length > 1) {
        // More than one physio, prepare a list
        const physioNames = await Promise.all(
          belongsToList.map((id) => getUserName(id))
        );
        setPhysioList(physioNames);
        setSelectedPhysio(physioNames[0]); // Set the first physio as the default
        getAthBusID(belongsToList[0]);
      } else if (belongsToList.length === 1) {
        const physioName = await getUserName(belongsToList[0]);
        setPhysioList([physioName]);
        setSelectedPhysio(physioName); // Set the single physio
        getAthBusID(belongsToList[0]); // Call getAthBusID if only one physio
      }
    } catch (error) {
      console.log("Error fetching physio data:", error);
    }
  };

  const getUserName = async (userID) => {
    const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;
    try {
      const response = await fetch(
        `https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/joinPhysio/getUsers?dataID=${userID}`
      );
      const userData = await response.json();
      const decryptedName = decryptField(userData.name, encryptionKey);
      return decryptedName;
    } catch (error) {
      console.log(error);
    }
  };

  const decryptField = (encryptedValue, encryptionKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedValue, encryptionKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  const getAthBusID = async (userid) => {
    try {
      const response = await fetch(
        `https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/business/getID?dataID=${userid}`
      );
      const data = await response.json();
      setAthBusID(data.bisID);

      if (data.bisID) {
        await getCliAPIKey(data.bisID); // Wait for getCliAPIKey to finish
      }
    } catch (error) {
      console.error("Error fetching business ID:", error);
    }
  };

  const getCliAPIKey = async (busID) => {
    try {
      const response = await fetch(
        `https://cmm7xmmede.execute-api.eu-west-2.amazonaws.com/test/business/getCLKey?dataID=${busID}`
      );
      const kdata = await response.json();
      setCLAPIKey(kdata.ClAPIKey);
    } catch (error) {
      console.error("Error fetching API key:", error);
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const param1 = searchParams.get("param1"); // patient name
    const param2 = searchParams.get("param2"); // physio name
    const param3 = searchParams.get("param3"); // physio ID
    const param4 = searchParams.get("param4"); // userID
    setLocalPatient(param1);
    setLocalPractitioner(param2);
    setPhysioID(param3);
    setUserID(param4);

    async function fetchData() {
      fetchPhysioData(param4);
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (clApiKey && localPractitioner && localPatient) {
      getPractitionerID();
      getPatientID();
      const bid = getBusinessID();
      setBusinessID(bid);
      //handleListAppointmentTypes();
      listBookings();
      getDailyAvs();
    }
  }, [clApiKey, localPractitioner, localPatient]);

  const handlePhysioChange = (e) => {
    const selectedName = e.target.value;
    setSelectedPhysio(selectedName);
  
    // Get the index of the selected physio name from physioList
    const selectedIndex = physioList.indexOf(selectedName);
  
    if (selectedIndex !== -1) {
      // Use the index to get the corresponding physio ID from physioListIDs
      const selectedPhysioID = physioListIDs[selectedIndex];
  
      // Fetch the business ID based on the selected physio ID
      getAthBusID(selectedPhysioID);
    } else {
      console.log("Error: Selected physio not found in the list");
    }
  };

  const getPractitionerID = async () => {
    const resp = await fetch(`https://elanugpyk9.execute-api.eu-west-2.amazonaws.com/apitest/api/practitioners`, {
      method: "GET",
      headers: {
        "x-api-key": clApiKey, // Pass the API key in custom headers
      },
    });
    const data = await resp.json();
    const practs = data.practitioners;
    const practitioner = practs.find(
      (pract) => pract.label === localPractitioner
    );
    setPRactitionerID(practitioner.id);
  
    // Await the completion of getPractitionerAppTypes before proceeding
    const appTypes = await getPractitionerAppTypes(practitioner.id);
  
    // Now call handleListAppointmentTypes after pracAppTypes is updated
    handleListAppointmentTypes(appTypes);
    return practitioner ? practitioner.id : null;
  };
  

  const getPractitionerAppTypes = async (practitionerID) => {
    try {
      const resp = await fetch(`https://elanugpyk9.execute-api.eu-west-2.amazonaws.com/apitest/api/practitionerData?practitionerID=${practitionerID}`, {
        method: "GET",
        headers: {
          "x-api-key": clApiKey, // Pass the API key in custom headers
        },
      });
      const data = await resp.json();
      
      // Extract appointment type names
      const appTypes = data.appointment_types.appointment_types.map(appType => appType.name);
      
      // Set the state with the appointment type names
      setPracAppTypes(appTypes);
      console.log('Appointment Types:', appTypes);
  
      // Return appTypes so it can be awaited
      return appTypes;
    } catch (error) {
      console.error('Error fetching practitioner appointment types:', error);
    }
  };
  

  const getPatientID = async () => {
    const nameParts = localPatient.split(" ");
    const lastName = nameParts[1];
    const resp = await fetch(`https://elanugpyk9.execute-api.eu-west-2.amazonaws.com/apitest/api/patientsFiltered?lastName=${lastName}`, {
      method: "GET",
      headers: {
        "x-api-key": clApiKey, // Pass the API key in custom headers
      },
    });
    const data = await resp.json();
    const patients = data.patients;
    const patient = patients.find((pat) => pat.label === localPatient);
    setPatientID(patient.id);
    return patient ? patient.id : null;
  };

  const getBusinessID = async () => {
    const apiUrl = "https://elanugpyk9.execute-api.eu-west-2.amazonaws.com/apitest/api/businesses";
    const resp = await fetch(apiUrl, {
      method: "GET",
      headers: {
        "x-api-key": clApiKey,
      },
    });
    const data = await resp.json();
    setBusinessData(data);
    setSelectedBusiness(data.businesses[0].business_name);
    console.log('business data:', data);
    const businessId = data.businesses[0].id;
    setBusinessID(businessId);
    return businessId;
  };

  const handleListAppointmentTypes = async (pracAppTypes) => {
    const resp = await fetch(`https://elanugpyk9.execute-api.eu-west-2.amazonaws.com/apitest/api/getAppTypes`, {
      method: "GET",
      headers: {
        "x-api-key": clApiKey,
      },
    });
    const data = await resp.json();
  
    // Filter the appointment types based on names in pracAppTypes
    const filteredAppointmentTypes = data.appointment_types.filter((type) =>
      pracAppTypes.includes(type.name)
    );
  
    // Extract the filtered names, ids, and duration times
    const types = filteredAppointmentTypes.map((type) => type.name);
    const ids = filteredAppointmentTypes.map((type) => type.id);
    const times = filteredAppointmentTypes.map((type) => type.duration_in_minutes);
  
    setAppointmentTypes(types);
    setAppointmentTypeIDs(ids);
    setAppointmentTypeTimes(times);
    console.log('Filtered Appointment Types:', types);
  };
  

  const listBookings = async () => {
    const resp = await fetch(`https://elanugpyk9.execute-api.eu-west-2.amazonaws.com/apitest/api/listBookings`, {
      method: "GET",
      headers: {
        "x-api-key": clApiKey,
      },
    });
    const data = await resp.json();
    console.log("bookings:", data);
    const formattedBookings = data.bookings.map((booking) => {
      return {
        id: booking.id,
        patientName: booking.patient_name,
        startTime: booking.starts_at,
        endTime: booking.ends_at,
        telehealthUrl: booking.telehealth_url,
      };
    });
    setBookings(formattedBookings);
  };

  const getDailyAvs = async () => {
    const resp = await fetch(
      `https://elanugpyk9.execute-api.eu-west-2.amazonaws.com/apitest/api/getDailyAvailability?practitionerId=${localPractitioner}`,
      {
        method: "GET",
        headers: {
          "x-api-key": clApiKey,
        },
      }
    );
    const data = await resp.json();
    console.log("daily:", data);
    const formattedSchedule = data.daily_availabilities.map((availability) => {
      return {
        dayOfWeek: availability.day_of_week,
        startTime: availability.availabilities[0].starts_at,
        endTime: availability.availabilities[0].ends_at,
        timeZone: availability.time_zone,
      };
    });

    setDailySchedule(formattedSchedule);
  };

  const handleSlotSelected = async (bookingDetails) => {
    const index = appointmentTypes.indexOf(bookingDetails.selectedType);
    const appType = bookingDetails.selectedType;
    const appTime = bookingDetails.selectedTime;
    const appointmentID = appointmentTypeIDs[index];
    const startDate = new Date(bookingDetails.slot.start);
    const endDate = new Date(bookingDetails.slot.end);
    const isoStart = startDate.toISOString();
    const isoEnd = endDate.toISOString();

    const queryParams = new URLSearchParams({
      practitionerId: practitionerID,
      patientId: patientID,
      businessId: businessID,
      appointmentTypeId: appointmentID,
      startDate: isoStart,
      endDate: isoEnd,
    }).toString();

    const resp = await fetch(
      `https://elanugpyk9.execute-api.eu-west-2.amazonaws.com/apitest/api/createIndividApp?${queryParams}`,
      {
        method: "POST",
        headers: {
          "x-api-key": clApiKey,
          "Content-Type": "application/json",
        },
      }
    );

    const data = await resp.json();
    console.log("Appointment booked with time:", data);
    const paymentParams = new URLSearchParams({
      appType: appType,
      appTime: appTime,
      startDate: isoStart,
    }).toString();

    navigate(`/athlete/payment?${paymentParams}`);
  };

  return (
    <div>
      <h3>Booking Page</h3>
      <p>
        {localPatient} booking in with {localPractitioner} at {selectedBusiness}
      </p>

      {physioList.length > 1 && (
        <div>
          <label htmlFor="physio-select">Select Physio:</label>
          <select
            id="physio-select"
            value={selectedPhysio}
            onChange={handlePhysioChange}
          >
            {physioList.map((physio, index) => (
              <option key={index} value={physio}>
                {physio}
              </option>
            ))}
          </select>
        </div>
      )}

      <MyCalendar
        bookings={bookings}
        dailySchedule={dailySchedule}
        types={appointmentTypes}
        times={appointmentTypeTimes}
        onSlotSelected={handleSlotSelected}
      />
    </div>
  );
};

export default Booking;
