import React from 'react';
import './footer.css';

const Footer = ({onLinkClick}) => {
  return (
    <footer>
      <p>&copy; {new Date().getFullYear()} Your Company. All rights reserved.</p>
      <a onClick={() => onLinkClick('privacy-policy')}>Privacy Policy</a>
    </footer>
  );
};

export default Footer;
